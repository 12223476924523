export const API_URL = `http://fitoutuae.com/api/v1`;

/**
 * Responsive breakpoints
 *
 * @type {{tablet: string, desktop: string, laptopL: string, mobileS: string, mobileM: string, laptop: string, mobileL: string}}
 */
export const BREAKPOINTS = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}
