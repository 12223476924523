import {
  SUBMIT_CONTACT_FORM_REQUEST,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_FAILURE,

  SUBMIT_SERVICE_FORM_REQUEST,
  SUBMIT_SERVICE_FORM_SUCCESS,
  SUBMIT_SERVICE_FORM_FAILURE,
} from '../actionTypes/forms';

const INITIAL_STATE = {
  submittingContactForm: false,
  submittingContactFormError: '',
  submittingContactFormSuccess: false,

  submittingServiceForm: false,
  submittingServiceFormError: '',
  submittingServiceFormSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_CONTACT_FORM_REQUEST:
      return { ...state,
        submittingContactForm: true,
        submittingContactFormSuccess: false,
        submittingContactFormError: '',
      };

    case SUBMIT_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        submittingContactForm: false,
        submittingContactFormSuccess: true,
        submittingContactFormError: '',
      };

    case SUBMIT_CONTACT_FORM_FAILURE:
      return { ...state,
        submittingContactForm: false,
        submittingContactFormSuccess: false,
        submittingContactFormError: payload,
      };

    case SUBMIT_SERVICE_FORM_REQUEST:
      return { ...state,
        submittingServiceForm: true,
        submittingServiceFormSuccess: false,
        submittingServiceFormError: '',
      };

    case SUBMIT_SERVICE_FORM_SUCCESS:
      return {
        ...state,
        submittingServiceForm: false,
        submittingServiceFormSuccess: true,
        submittingServiceFormError: '',
      };

    case SUBMIT_SERVICE_FORM_FAILURE:
      return { ...state,
        submittingServiceForm: false,
        submittingServiceFormSuccess: false,
        submittingServiceFormError: payload,
      };

    default:
      return state;
  }
};
