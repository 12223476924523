import { createGlobalStyle, css } from 'styled-components';
import { BREAKPOINTS } from '../../../_services/constants';

export const responsiveQuery = Object.keys(BREAKPOINTS).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${BREAKPOINTS[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export const squareGridBackground = css`
  background-image:
    linear-gradient(to bottom, transparent 94%, rgba(201, 197, 182, 0.3) 94%),
    linear-gradient(to right, rgba(0,0,0,0) 94%, rgba(201, 197, 182, 0.3) 94%);
  background-size: 15px 15px, 15px 15px;
`;

export const mixins = {
  background: color => `background: ${color};`,
};

export const containerMixin = css`
  width: 100%;
  margin: 0 auto;
  max-width: none;
  padding: 0 var(--container-padding);
  ${responsiveQuery.desktop`
    max-width: var(--max-width-desktop);
  `}
  ${responsiveQuery.laptopL``}
  ${responsiveQuery.laptop``}
  ${responsiveQuery.tablet``}
  ${responsiveQuery.mobileL``}
  ${responsiveQuery.mobileM``}
  ${responsiveQuery.mobileS``}
`;

const GlobalStyles = createGlobalStyle`
  @font-face {
      font-family: 'Helvetica';
      src: url('../../../../fonts/Helvetica/Helvetica.ttf') format('truetype');
      font-style: normal;
      font-weight: normal;
  }
  @font-face {
    font-family: 'HK Grotesk';
    src: url('../../../../fonts/HKGrotesk/HKGrotesk-Regular.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
      font-family: 'HK Grotesk';
      src: url('../../../../fonts/HKGrotesk/HKGrotesk-SemiBold.otf') format('opentype');
      font-style: normal;
      font-weight: bold;
  }
  :root {
    --container-padding: 6.6rem;
    --grid-cut: 17.8rem;
    --header-height: 9.9rem;
    --global-transition: 0.2s ease-in-out;
    --max-width-desktop: 160rem;
  }
  ${responsiveQuery.tablet`
    :root {
      --header-height: 9.9rem;
    }§
  `}
  ${responsiveQuery.mobileL`
    :root {
      --container-padding: 3.3rem;
      --grid-cut: 17.8rem;
      --header-height: 9.9rem;
      --global-transition: 0.2s ease-in-out;
      --max-width-desktop: 160rem;
    }
  `}
  *, *:before, *:after  {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 62.5%;
    overflow-x: hidden;
  }
  body {
    color: #000000;
    width: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  ::selection {
    background: black;
    color: white;
  }
`;

export default GlobalStyles;
