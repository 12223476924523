import React from 'react';

import GlobalStyles from './GlobalStyles';

const LayoutWrap = ({ children }) => (
    <>
      <GlobalStyles />
      <div>{children}</div>
    </>
  );

export default LayoutWrap;
