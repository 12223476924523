import React from 'react';
import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import reducers from './reducers/index';
import LayoutWrap from '../components/Layouts/LayoutWrap/LayoutWrap';

const windowGlobal = typeof window !== 'undefined' && window;
const reduxDevToolsEnchancer =
  process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;
const createStoreWithMiddleware = applyMiddleware(thunk)(reduxCreateStore);
const createStore = () => createStoreWithMiddleware(reducers, reduxDevToolsEnchancer);

export default ({ element, props }) => {
  return <Provider store={createStore()}><LayoutWrap {...props}>{element}</LayoutWrap></Provider>;
};
